<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) For a second-order reaction, if
        <number-value :value="percentage" />
        <stemble-latex content="$\%$" />
        of the original
        <number-value :value="initialConc" />
        <stemble-latex content="$\mathrm{M}$" />
        sample has decomposed after
        <number-value :value="reactionTime" />
        <stemble-latex content="$\mathrm{s}$" />
        , what is the rate constant for the reaction?
      </p>

      <calculation-input
        v-model="inputs.rateConstant"
        prepend-text="$k$"
        append-text="$\text{M}^{-1}\text{s}^{-1}$"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-3">b) What is the half-life for the reaction?</p>

      <calculation-input
        v-model="inputs.halfLife"
        :prepend-text="'$\\mathrm{t}_{\\text{1/2}}$'"
        append-text="s"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question153',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rateConstant: null,
        halfLife: null,
      },
    };
  },
  computed: {
    reactionTime() {
      return this.taskState.getValueBySymbol('reactionTime').content;
    },
    initialConc() {
      return this.taskState.getValueBySymbol('initialConc').content;
    },
    percentage() {
      return this.taskState.getValueBySymbol('percentage').content;
    },
  },
};
</script>
